import {
	ImageZoom,
	StyledBusContainer,
	StyledBusData,
	StyledGroup,
	StyledImageContainer,
} from '../../styles/tabor';
import React, { useEffect, useState } from 'react';

import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Layout } from '../../components/Layout';
import { StyledArticle } from '../../styles';
import { StyledH2 } from '../../styles/common';
import { graphql } from 'gatsby';
import { SEO } from '../../components/SEO';

export const Head = () => (
	<SEO 
	title='Tabor autobusowy'
	description='Tabor autobusowy.'
	/>);

const isBrowser = () => typeof window !== 'undefined';

const AutobusyPage = ({ data }) => {
	const [zoom, setZoom] = useState(false);
	const [image, setImage] = useState(null);
	const [isMobile, setIsMobile] = useState(true);
	let staraGrupa = '';
	const { allAutobusyJson } = data;
	const { edges } = allAutobusyJson;

	useEffect(() => {
		if (isBrowser()) {
			window.addEventListener('scroll', handleScrollEvent);
			handleOnResize();
		}
		return () => {
			if (isBrowser()) {
				window.removeEventListener('scroll', handleScrollEvent);
			}
		};
	});

	const handleScrollEvent = () => {
		setZoom(false);
	};

	useEffect(() => {
		if (isBrowser()) {
			window.addEventListener('resize', handleOnResize);
		}
		return () => {
			if (isBrowser()) {
				window.removeEventListener('resize', handleOnResize);
			}
		};
	}, []);

	const handleOnResize = () => {
		setIsMobile(isBrowser() ? window.innerWidth < 800 : false);
	};
	return (
		<>
			<Layout>
				<StyledArticle>
					<StyledH2 as="h1" color="info">
						Tabor autobusowy
					</StyledH2>
					{edges.map(({ node }) => {
						const {
							grupa,
							obrazek,
							nazwa,
							rok,
							typ,
							silnik,
							skrzynia,
							siedzace,
							stojace,
							dmc,
							sztuk,
						} = node;
						let czyNowaGrupa = false;
						if (staraGrupa !== grupa) {
							czyNowaGrupa = true;
						}
						staraGrupa = grupa;
						return (
							<div key={`${nazwa} ${rok}`}>
								{czyNowaGrupa ? <StyledGroup>{grupa}</StyledGroup> : null}
								<StyledBusContainer>
									<StyledImageContainer
										onClick={() => {
											if (!isMobile) {
												setZoom(true);
												setImage(getImage(obrazek.childImageSharp.i1));
											}
										}}
									>
										<GatsbyImage image={getImage(obrazek.childImageSharp.i2)} alt="" />
										<div className="name">{nazwa}</div>
									</StyledImageContainer>
									<StyledBusData>
										<table>
											<tbody>
												<tr>
													<td>Rok produkcji:</td>
													<td>{rok}</td>
												</tr>
												<tr>
													<td>Typ:</td>
													<td>{typ}</td>
												</tr>
												<tr>
													<td>Typ silnika:</td>
													<td>{silnik}</td>
												</tr>
												<tr>
													<td>Typ skrzyni:</td>
													<td>{skrzynia}</td>
												</tr>
												<tr>
													<td>Il.miejsc&nbsp;siedzacych:</td>
													<td>{siedzace}</td>
												</tr>
												<tr>
													<td>Il.miejsc&nbsp;stojących:</td>
													<td>{stojace}</td>
												</tr>
												<tr>
													<td>D.M.C:</td>
													<td>{dmc}</td>
												</tr>
												<tr>
													<td>Sztuk:</td>
													<td>{sztuk}</td>
												</tr>
											</tbody>
										</table>
									</StyledBusData>
								</StyledBusContainer>
							</div>
						);
					})}
				</StyledArticle>
			</Layout>
			{zoom ? (
				<ImageZoom onClick={() => setZoom(false)}>
					<GatsbyImage image={image} objectFit="cover"/>
				</ImageZoom>
			) : null}
			;
		</>
	);
};

export const pageQuery = graphql`
	query {
		allAutobusyJson {
			edges {
				node {
					grupa
					nazwa
					rok
					typ
					silnik
					skrzynia
					dmc
					siedzace
					stojace
					sztuk
					id
					obrazek {
						childImageSharp {
							i1: gatsbyImageData(layout: FIXED, width: 640) 
							i2: gatsbyImageData(layout: FULL_WIDTH, quality:85)
						}
					}
				}
			}
		}
	}
`;

export default AutobusyPage;
